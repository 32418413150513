// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-dropdown {
  margin-left: 30px;
  position: relative;
  cursor: pointer;
  padding: 8px;
}
.profile-dropdown:hover .profile-dropdown__options {
  opacity: 1;
  transform: translateY(-5px);
}
.profile-dropdown__circle {
  display: flex;
}
.profile-dropdown__avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.profile-dropdown__arrow {
  margin-left: -10px;
}
.profile-dropdown__options {
  position: absolute;
  background-color: #ececec;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.05);
  width: 120px;
  right: 20px;
  margin-top: 10px;
  z-index: 2;
  opacity: 0;
  transform: translateY(-300px);
  transition: all 400ms cubic-bezier(0, 0.3, 0.69, 1);
}
.profile-dropdown__info {
  border-bottom: 1px solid #dcdde0;
  background-color: #fff;
  border-radius: 4px;
  line-height: 20px !important;
}
.profile-dropdown__item {
  display: block;
  padding: 10px;
  line-height: 10px;
}
.profile-dropdown__link {
  transition: all ease 0.3s;
  margin-top: 5px;
}
.profile-dropdown__link:hover {
  color: #549E35;
}`, "",{"version":3,"sources":["webpack://./src/components/header/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AACF;AAEI;EACE,UAAA;EACA,2BAAA;AAAN;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,UAAA;EACA,UAAA;EACA,6BAAA;EAEA,mDAAA;AANJ;AASE;EACE,gCAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;AAPJ;AAUE;EACE,cAAA;EACA,aAAA;EACA,iBAAA;AARJ;AAWE;EACE,yBAAA;EACA,eAAA;AATJ;AAUI;EACE,cAAA;AARN","sourcesContent":[".profile-dropdown {\n  margin-left: 30px;\n  position: relative;\n  cursor: pointer;\n  padding: 8px;\n\n  &:hover {\n    .profile-dropdown__options {\n      opacity: 1;\n      transform: translateY(-5px);\n    }\n  }\n\n  &__circle {\n    display: flex;\n  }\n\n  &__avatar {\n    width: 46px;\n    height: 46px;\n    border-radius: 50%;\n  }\n\n  &__arrow {\n    margin-left: -10px;\n  }\n\n  &__options {\n    position: absolute;\n    background-color: #ececec;\n    padding: 7px;\n    border-radius: 5px;\n    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.05);\n    width: 120px;\n    right: 20px;\n    margin-top: 10px;\n    z-index: 2;\n    opacity: 0;\n    transform: translateY(-300px);\n    // transition: all 400ms cubic-bezier(0, 0.3, 0.69, 1.91);\n    transition: all 400ms cubic-bezier(0, 0.3, 0.69, 1.0);\n  }\n\n  &__info {\n    border-bottom: 1px solid #dcdde0;\n    background-color: #fff;\n    border-radius: 4px;\n    line-height: 20px !important;\n  }\n\n  &__item {\n    display: block;\n    padding: 10px;\n    line-height: 10px;\n  }\n\n  &__link {\n    transition: all ease 0.3s;\n    margin-top: 5px;\n    &:hover {\n      color: #549E35;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
